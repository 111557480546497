/* You can add global styles to this file, and also import other style files */

html,
body {
    overflow: scroll;
    scrollbar-width: none;
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

#gradient {
    background-image: linear-gradient(lightskyblue, lightskyblue, lightskyblue);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
