.node {
    cursor: pointer;
}

.overlay {
    background-color: #EEE;
}

.node circle {
    fill: #fff;
    stroke: steelblue;
    stroke-width: 1.5px;
}

.node text {
    font-size: 10px;
    font-family: sans-serif;
}

.link {
    fill: none;
    stroke: #ccc;
    stroke-width: 1.5px;
}

.templink {
    fill: none;
    stroke: red;
    stroke-width: 3px;
}

.ghostCircle.show {
    display: block;
}

.ghostCircle,
.activeDrag .ghostCircle {
    display: none;
}

div.tooltip {
    position: absolute;
    text-align: center;
    /* width: 80px; */
    /* height: 35px; */
    padding: 2px;
    font: 12px sans-serif;
    background: lightsteelblue;
    border: 0px;
    border-radius: 8px;
    pointer-events: none;
}

#reset {
    display: block !important;
    position: absolute;
    margin: 1.5rem 4.5rem;
}

.zoom {
    display: block !important;
    position: absolute;
    margin: 1.5rem;
}

#zoom_out {
    margin: 1.5rem 3rem;
}
.ui-menu-item .ui-menu-item-wrapper.ui-state-active {
    background: #EEE ;
    font-weight: bold ;
    color: #585858 ;
}
